import React from 'react'
import Logo from "../Assets/Growrex transparent logo.png"

function Footer() {
    return (  
        <div className='bg-zinc-800 rounded-t-3xl px-10 lg:px-24 py-6 lg:py-20'>
            <div className='max-w-screen-xl mx-auto flex flex-col lg:flex-row items-center justify-between gap-10'>
            <div className=''>
                <img src={Logo} alt='Logo' className='w-48'/>
            </div>
            <div className='font-secondary flex flex-col lg:flex-row gap-6 text-white text-xl'>
                <a href='#' className='hover:text-[#fff000]'>Careers</a>
                <a href='#' className='hover:text-[#fff000]'>Media Kit</a>
                <a href='https://twitter.com/growrex_io?s=21' className='hover:text-[#fff000]'>Twitter</a>
                <a href='https://www.linkedin.com/company/growrex/' className='hover:text-[#fff000]'>Linkedin</a>
                <a href="mailto:Mohit@Growrexmedia.com" className='hover:text-[#fff000]'>E-Mail</a>
            </div>
            <div className='font-secondary text-white text-xl text-center'>
                <p>© 2024 Growrex. All rights reserved </p>
            </div>
            </div>
        </div>
    );
}

export default Footer