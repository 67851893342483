import React, { useEffect, useState } from 'react';
import Globe from "../Assets/globe.png";
import Telegram from '../Assets/telegram-logo-blue.svg';
import ball1 from "../Assets/01.png";
import ball2 from "../Assets/02.png";

function Hero() {
    const [text, setText] = useState('');

    useEffect(() => {
        const originalText = "Helping WEB3 Brands";
        let currentIndex = 0;

        const interval = setInterval(() => {
            if (currentIndex <= originalText.length) {
                setText(originalText.slice(0, currentIndex));
                currentIndex++;
            } else {
                clearInterval(interval);
            }
        }, 100); // Adjust the interval duration (in milliseconds) as needed

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="relative">
            {/* Background balls */}
            <div className="hidden lg:block absolute top-0 left-0 w-full h-full z-0">
                <img src={ball1} alt="ball1" className="absolute top-1/2  transform -translate-x-1/2 -translate-y-1/2 opacity-70" />
                <img src={ball2} alt="ball2" className="absolute top-1/4 left-1/3 transform -translate-x-1/2 -translate-y-1/2 opacity-70" />
                <img src={ball2} alt="ball2" className="absolute bottom-1/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-70" />
            </div>
            {/* Content */}
            <div className="max-w-screen-xl mx-auto flex flex-col-reverse md:flex-row px-10 pt-20 relative z-10">
                <div className="w-full lg:w-3/5 pt-20">
                    <h1 className="text-white text-5xl font-secondary font-semibold lg:text-6xl mb-8 text-center lg:text-left">
                        {text}
                    </h1>
                    <h1 className="text-white text-6xl lg:text-8xl mb-8 text-center lg:text-left font-semibold font-secondary">
                        Go <span className="text-[yellow]">Viral</span>!
                    </h1>
                    <p className="text-white text-3xl mb-8 font-secondary text-center lg:text-left">An all-in-one Marketing firm</p>
                    <div className="flex gap-4 mb-8 justify-center font-secondary lg:justify-start">
                        <a
                            href="https://t.me/TGMohit"
                            className="button flex gap-2 bg-[#fff000] font-bold text-xl p-4 text-black hover:bg-white hover:text-gray-900 rounded-xl"
                        >Chat on
                            <img
                                src={Telegram}
                                alt="telegram"
                                className="w-6"
                            /></a>
                        <a
                            href="https://calendly.com/xmohit/growrex"
                            className="button flex bg-[#fff000] font-bold text-xl p-4 text-black hover:bg-white hover:text-gray-900 rounded-xl"
                        >Let's Talk</a
                        >
                    </div>
                    <p className="text-white text-4xl text-center lg:text-left">Trusted by the OGs</p>
                </div>
                <div className="w-full lg:w-2/5 pt-10">
                    <img src={Globe} alt='globe' />
                </div>
            </div>
        </div>
    );
}

export default Hero;
