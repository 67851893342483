import React from 'react'
import ball2 from "../Assets/02.png";
function Meeting() {
    return (
        <div className="relative">
            {/* Background balls */}
            <div className="lg:block absolute top-0 left-0 w-full h-full z-0 hidden">
                <img src={ball2} alt="ball1" className="absolute top-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-70" />
                <img src={ball2} alt="ball2" className="absolute top-1/4 left-1/3 transform -translate-x-1/2 -translate-y-1/2 opacity-70" />
                <img src={ball2} alt="ball2" className="absolute bottom-1/3 right-1/4 transform -translate-x-1/2 -translate-y-1/2 opacity-70" />
            </div>
            <div className='bg-gradient-to-t from-black rounded-t-3xl to-zinc-800 pt-16'>
                <div className='max-w-screen-xl mx-auto px-10 lg:px-24 flex flex-col text-center text-white items-center gap-8 py-10'>
                    <h1 className='text-5xl lg:text-6xl font-secondary font-bold'>Ready For Action?</h1>
                    <h1 className='text-3xl lg:text-5xl font-semibold'>Let’s Book A Call With Your Growth Frens!</h1>
                    <a className='button bg-[#fff000] mb-2 font-semibold text-xl p-3 text-black hover:bg-white hover:text-gray-900 rounded-xl' href='https://calendly.com/xmohit/growrex'>Book a Meeting</a>
                    <div className="calendly-inline-widget h-[700px] w-full" data-url="https://calendly.com/xmohit/growrex"></div>
                </div>
            </div>
        </div>
    );
}

export default Meeting;