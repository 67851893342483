import React, { useState, useEffect } from 'react';
import Logo from "../Assets/Growrex transparent logo1.png";
import Telegram from "../Assets/telegram-logo-blue.svg";

function Navbar() {
    const [menuOpen, setMenuOpen] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [hoveredItem, setHoveredItem] = useState(null);

    useEffect(() => {
        const handleScroll = () => {
            const position = window.pageYOffset;
            setScrollPosition(position);
        };

        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleHover = (item) => {
        setHoveredItem(item);
    };

    return (
        <div className={`px-10 lg:px-24 py-6 w-full ${scrollPosition > 0 ? 'bg-zinc-800' : 'bg-black'} border-gray-700 border-b-2 fixed top-0 left-0 z-30 transition duration-300 ease-in-out`}>
            <nav className="max-w-screen-xl mx-auto flex items-center justify-between">
                <img src={Logo} alt='logo' className="w-40" />
                <div className='hidden lg:flex'>
                    <div className="font-semibold text-xl text-white flex flex-col lg:flex-row gap-10">
                        <a href="#about" className={`hover:text-white ${hoveredItem === 'services' || hoveredItem === 'testimonials' ? 'text-zinc-400' : ''}`} onMouseEnter={() => handleHover('about')} onMouseLeave={() => handleHover(null)}>About Us</a>
                        <a href="#services" className={`hover:text-white ${hoveredItem === 'about' || hoveredItem === 'testimonials' ? 'text-zinc-400' : ''}`} onMouseEnter={() => handleHover('services')} onMouseLeave={() => handleHover(null)}>Services</a>
                        <a href="#testimonials" className={`hover:text-white ${hoveredItem === 'services' || hoveredItem === 'about' ? 'text-zinc-400' : ''}`} onMouseEnter={() => handleHover('testimonials')} onMouseLeave={() => handleHover(null)}>Testimonials</a>
                    </div>
                </div>
                <div className='hidden lg:flex'>
                    <div className="flex flex-col lg:flex-row gap-8">
                        <a href="https://t.me/TGMohit" className="button flex gap-2 bg-[#fff000] font-semibold text-xl p-2 text-black hover:bg-white hover:text-gray-900 rounded-xl">Chat on <img src={Telegram} alt="telegram" className="w-6" /></a>
                        <a href="https://calendly.com/xmohit/growrex" className="button flex gap-2 bg-[#fff000] font-semibold text-xl p-2 text-black hover:bg-white hover:text-gray-900 rounded-xl">Let's Talk</a>
                    </div>
                </div>
                <div className="lg:hidden">
                    <button onClick={toggleMenu} className="block text-white focus:outline-none">

                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path stroke="white" fill='white' d="M4 6h16v2H4zm0 5h16v2H4zm0 5h16v2H4z" />
                        </svg>
                    </button>
                </div>
                {menuOpen && (
                    <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-50">
                        <div className="h-full w-64 bg-black fixed top-0 left-0 shadow-lg">
                            <div className="px-6 py-4 flex justify-between items-center border-b border-gray-300">
                                <img src={Logo} alt='logo' className="w-20" />
                                <button onClick={toggleMenu} className="focus:outline-none">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="white" viewBox="0 0 24 24" stroke="white">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>
                            <div className="py-4">
                                <a href="#about" className="block px-4 py-2 text-white hover:bg-gray-200">About Us</a>
                                <a href="#services" className="block px-4 py-2 text-white hover:bg-gray-200">Services</a>
                                <a href="#testimonials" className="block px-4 py-2 text-white hover:bg-gray-200">Testimonials</a>
                            </div>
                            <div className="py-4">
                                <a href="https://t.me/TGMohit" className="button m-4 flex gap-2 bg-[#fff000] font-semibold text-xl p-2 text-black hover:bg-white hover:text-gray-900 rounded-xl">Chat on <img src={Telegram} alt="telegram" className="w-6" /></a>
                                <a href="https://calendly.com/xmohit/growrex" className="button m-4 flex gap-2 bg-[#fff000] font-semibold text-xl p-2 text-black hover:bg-white hover:text-gray-900 rounded-xl">Let's Talk</a>
                            </div>
                        </div>
                    </div>
                )}
            </nav>
        </div>
    );
}

export default Navbar;
