import React from 'react'
import CountUp from 'react-countup';
import ball2 from "../Assets/02.png";

function Counter() {
    return (
        <div className="relative">
            {/* Background balls */}
            <div className="hidden lg:block absolute top-0 left-0 w-full h-full z-0">
                <img src={ball2} alt="ball1" className="absolute bottom-1/4 right-1/3 transform -translate-x-1/2 -translate-y-1/2 opacity-70" />
                <img src={ball2} alt="ball2" className="absolute top-1/4 left-1/3 transform -translate-x-1/2 -translate-y-1/2 opacity-70" />
                
            </div>
            <div className="absolute top-0 left-0 w-full h-full z-0">
            <div className='absolute top-0 left-0 lg:-left-20 h-1/2 w-20 lg:h-48 lg:w-32 bg-[#fff000]' style={{
                filter: 'blur(250px)',
                borderRadius: '50% 0 0 50%',
                zIndex: '-1'
            }}></div>
            </div>
            <div id='about' className='px-4 lg:px-24 flex flex-col text-white items-center gap-6 py-6 lg:py-12'>
                <h1 className='text-4xl lg:text-5xl font-semibold font-secondary text-white mb-10 lg:mb-16 text-center'>Our Numbers Speak for Themselves</h1>
                <div className="flex flex-wrap items-center justify-start lg:justify-center gap-8 lg:gap-16 my-6 px-4">
                    <div className="flex flex-row items-center justify-center gap-6">
                        <div className="rounded-full border border-gray-500 p-4">
                            <i className="fas fa-eye text-[#fff000] text-2xl"></i>
                        </div>
                        <div className="flex flex-col">
                            <h1 className="text-3xl lg:text-5xl text-white font-bold font-secondary"><CountUp start={0} end={200} duration={2} /> Million+</h1>
                            <p className="text-xl text-white font-secondary">Impression</p>
                        </div>
                    </div>
                    <div className="flex flex-row items-center justify-center gap-6">
                        <div className="rounded-full border border-gray-500 p-4">
                            <i className="fas fa-sharp fa-light fa-user text-[#fff000] text-2xl"></i>
                        </div>
                        <div className="flex flex-col">
                            <h1 className="text-3xl lg:text-5xl text-white font-bold font-font-1"><CountUp start={0} end={3000} duration={2} />+ KOL</h1>
                            <p className="text-xl text-white font-secondary">Network</p>
                        </div>
                    </div>
                    <div className="flex flex-row items-center justify-center gap-6">
                        <div className="rounded-full border border-gray-500 p-4">
                            <i className="fas fa-sharp fa-light fa-calendar text-[#fff000] text-2xl"></i>
                        </div>
                        <div className="flex flex-col">
                            <h1 className="text-3xl lg:text-5xl text-white font-bold font-font-1"><CountUp start={0} end={125} duration={2} />+</h1>
                            <p className="text-xl text-white font-secondary">Offline events</p>
                        </div>
                    </div>
                    <div className="flex flex-row items-center justify-center gap-6">
                        <div className="rounded-full border border-gray-500 p-4">
                            <i className="fas fa-light fa-pencil-alt text-[#fff000] text-2xl"></i>
                        </div>
                        <div className="flex flex-col">
                            <h1 className="text-3xl lg:text-5xl text-white font-bold font-font-1"><CountUp start={0} end={15} duration={2} /> K+</h1>
                            <p className="text-xl text-white font-secondary">Content Created</p>
                        </div>
                    </div>
                    <div className="flex flex-row items-center justify-center gap-6">
                        <div className="rounded-full border border-gray-500 p-4">
                            <i className="fas fa-sharp fa-light fa-chart-line text-[#fff000] text-2xl"></i>
                        </div>
                        <div className="flex flex-col">
                            <h1 className="text-3xl lg:text-5xl text-white font-bold font-font-1"><CountUp start={0} end={4} duration={2} /> Million+</h1>
                            <p className="text-xl text-white font-secondary">Followers grown</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Counter;
