
import CoinDCX from "../Assets/Partners/coindcx.png";
import Density from "../Assets/Partners/density.svg";
import Levilate from "../Assets/Partners/levilate.svg";
import OKX from "../Assets/Partners/OKX.png";
import OnlyDust from "../Assets/Partners/OnlyDust.png";
import BNB from "../Assets/Partners/BNB Chain.png";
import Optimism from "../Assets/Partners/Optimism.svg";
import Particle from "../Assets/Partners/Particle Network.png";
import StarkNet from "../Assets/Partners/StarkNet.png";
import Saarchain from "../Assets/Partners/saarchain.png";
import Vertex from "../Assets/Partners/vertex.png";
import Marquee from "react-fast-marquee";

const Partners = () => {
  return (
    <>
       <section className='flex flex-col text-white items-center gap-6 pb-10'>
        <Marquee className="">
          <div className="flex justify-center items-center gap-16">
            <a href="/">
              <img className="md:max-w-[200px] max-w-[150px] pl-6" alt="partners" src={CoinDCX} />
            </a>
            <a href="/">
              <img className="md:max-w-[200px] max-w-[150px]" alt="partners" src={Density} />
            </a>
            <a href="/">
              <img className="md:max-w-[200px] max-w-[150px]" alt="partners" src={Levilate} />
            </a>
            <a href="/">
              <img className="max-w-[100px]" alt="partners" src={OKX} />
            </a>
            <a href="/">
              <img className="md:max-w-[200px] max-w-[170px]" alt="partners" src={OnlyDust} />
            </a>
            <a href="/">
              <img className="md:max-w-[200px] max-w-[150px]" alt="partners" src={BNB} />
            </a>
            <a href="/">
              <img className="md:max-w-[200px] max-w-[150px]" alt="partners" src={Vertex} />
            </a>
            <a href="/">
              <img className="md:max-w-[200px] max-w-[150px]" alt="partners" src={Saarchain} />
            </a>
            <a href="/">
              <img className="md:max-w-[200px] max-w-[150px]" alt="partners" src={StarkNet} />
            </a>
            <a href="/">
              <img className="md:max-w-[200px] max-w-[170px]" alt="partners" src={Optimism} />
            </a>
            <a href="/">
              <img className="md:max-w-[200px] max-w-[170px]" alt="partners" src={Particle} />
            </a>
          </div>
        </Marquee>
      </section>
    </>
  );
};

export default Partners;
