
import './App.css';
import Footer from './Components/Footer';
import Meeting from './Components/Meeting';
import Expertise from './Components/Expertise';
import Counter from './Components/Counter';
import Partners from './Components/Partners';
import Hero from './Components/Hero';
import Navbar from './Components/Navbar';

function App() {
  return (
    <div className="bg-black">
      <Navbar/>
      <Hero/>
      <Partners/>
      <Counter/>
      <Expertise/>
      <Meeting/>
      <Footer/>
    </div>
  );
}

export default App;
