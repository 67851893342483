import React from 'react'
import ExpertiseImage from "../Assets/Expertise.png"
import ExpertiseImage2 from "../Assets/expertise mobile.png"
import ball2 from "../Assets/02.png";

function Expertise() {
    return (
        <div className="relative" id='services'>
            {/* Background balls */}
            <div className="hidden lg:block absolute top-0 left-0 w-full h-full z-0">
                <img src={ball2} alt="ball2" className="absolute top-1/4 left-1/3 transform -translate-x-1/2 -translate-y-1/2 opacity-70" />
                <img src={ball2} alt="ball2" className="absolute bottom-1/4 right-1/3 transform -translate-x-1/2 -translate-y-1/2 opacity-70" />
            </div>
            <div className='max-w-screen-xl mx-auto px-4 lg:px-12 flex flex-col text-white items-center gap-6 py-10'>
                <h1 className='text-4xl lg:text-5xl font-semibold font-secondary mb-8 lg:mb-16 text-center'>Our Expertise</h1>
                <img src={ExpertiseImage} alt='Expertise' className='lg:block hidden' />
                <img src={ExpertiseImage2} alt='Expertise' className='block lg:hidden' />
            </div>
        </div>
    );
}

export default Expertise;
